import styles from "./Header.module.css";
import { Link } from "react-router-dom";

const Header = ({ navAboutHandler, navReviewsHandler, navPortfolioHandler, navContactHandler}) => {
    return <header role="banner" className={styles.banner}>
    <div className={styles.container}>
        <div className={styles.content}>
            <nav role="navigation">
                <ul>
                    <li>
                        <Link to="#about" onClick={navAboutHandler}>About</Link>
                    </li>                    
                    <li>
                        <Link to="#portfolio" onClick={navPortfolioHandler}>Portfolio</Link>
                    </li>
                    <li> 
                        <Link to="#reviews" onClick={navReviewsHandler}>Reviews</Link>
                    </li>                    
                    <li>
                        <Link to="#contact" onClick={navContactHandler}>Contact</Link>
                    </li>
                </ul>
            </nav>
            <div className={styles.pic}>
                <figure>
                    <img src="/ioan-istrate-web-developer-no-frame.jpg" alt="Your favorite web developer" />
                </figure>
            </div>
            <div className={styles.info}>
                <h1>
                    My name is ioan [E-One] <br />
                    <em>&& I am a Senior Software Engineer</em>
                </h1>
            </div>
        </div>
    </div>
</header>
}

export default Header;