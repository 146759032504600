import styles from "./Footer.module.css";

const Footer = () => {

    return  <footer role="contentinfo" className={styles.contentinfo}>
            <div className={styles.container}>
                <div className={styles.content}>
                    <p>
                        Built with <em>love</em> with <strong>React</strong><br />
                        <small>copyright <span>{new Date().getFullYear()}</span></small>
                    </p>
                </div>
            </div>
        </footer>
}
export default Footer;