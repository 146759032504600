import styles from "./PortfolioItem.module.css";

const PortfolioItem = ({item}) => {
    return <div className={styles.item}>
        <figure>
            <img src={'/' + item.imageURL} alt={item.goodies} />
        </figure> 
        <div className={styles.info}>
            <dl>
                <dt>Tech:</dt>
                <dd>{item.tech}</dd>
                <dt>Goodies:</dt>
                <dd>{item.goodies}</dd>
                <dt>Browsers:</dt>
                <dd>{item.browsers}</dd>
            </dl>
        </div>
    </div>
} 
export default PortfolioItem;