import { useLayoutEffect, useRef, useState } from "react";
import { Outlet } from "react-router-dom";
import Footer from "../components/Footer";
import Header from "../components/Header";
import PortfolioItem from "../components/PortfolioItem";

import styles from "./Root.module.css";

const DUMMY_PORTFOLIO_DATA = [
    {
        "imageURL": "portfolio-item-4.jpg",
        "tech": "HTML5, CSS3, JavaScript",
        "goodies": "jQuery, SASS",
        "browsers": "Edge, Chrome, Safari, Firefox"
    },
    {
        "imageURL": "portfolio-item-2.jpg",
        "tech": "HTML5, CSS3, JavaScript",
        "goodies": "jQuery, SASS",
        "browsers": "Edge, Chrome, Safari, Firefox"
    },
    {
        "imageURL": "portfolio-item-5.jpg",
        "tech": "HTML5, CSS3, JavaScript",
        "goodies": "jQuery, SASS",
        "browsers": "Edge, Chrome, Safari, Firefox"
    },
    {
        "imageURL": "portfolio-item-1.jpg",
        "tech": "HTML5, CSS3, JavaScript",
        "goodies": "jQuery, SASS",
        "browsers": "Edge, Chrome, Safari, Firefox"
    },
    {
        "imageURL": "portfolio-item-3.jpg",
        "tech": "HTML5, CSS3, JavaScript",
        "goodies": "jQuery, SASS",
        "browsers": "Edge, Chrome, Safari, Firefox"
    },
    {
        "imageURL": "portfolio-item-6.jpg",
        "tech": "HTML5, CSS3, JavaScript",
        "goodies": "jQuery, SASS",
        "browsers": "Edge, Chrome, Safari, Firefox"
    },  
    {
        "imageURL": "portfolio-item-7.jpg",
        "tech": "HTML5, CSS3, JavaScript",
        "goodies": "jQuery, SASS",
        "browsers": "Edge, Chrome, Safari, Firefox"
    }   
]

const DUMMY_REVIEW_DATA = [
    {
        "review": "Ioan did an excellent job, his code was literally perfect! We gave the contract to Ioan on Wednesday Morning, Thursday morning we received an email that the job had been completed. We opened the attached files, tested, looked through the code and it was excellent. We will be hiring Ioan again in the future as he is completely dependable and his work speaks for itself!",
        "person": "- client"
    },
    {
        "review": "Ioan was a great contractor. It is not readily apparent from the dates of this assignment, but he turned around a TON of work in a week--more work than anticipated in less time quoted in fact. This assignment only appears to have run over a month because, as an oDesk newbie, I did not close it out. Working with more ambiguity than we would have liked, Ioan powered through about 20 mockups and produced fully functional web pages in less than a week",
        "person": "- client"
    },
    {
        "review": "Ioan did an awesome job!  I would hire him again without any hesitation. Not only did he lived up to my expectations based on the other oDesk feedback, he exceeded them. His communication was excellent.  He delivered on time, and under budget.  The code I received was of very high quality (I'm a software developer so I knew what I was looking at).  Overall, I was extremely happy with the job.",
        "person": "- client"
    },
    {
        "review": "Ioan was incredibly fast, knowledgeable and a great communicator.  Will definitely hire in the future and would recommend to anyone looking for any CSS, JavaScript fixing.",
        "person": "- client"
    },
    {
        "review": "What can I say Ioan is my go to guy for any cutup/slicing project.  He always delivers the best quality, always professional and punctual.  Hire him and rest assured that the work will get done right.  A+",
        "person": "- client"
    },

    {
        "review": "Ioan provided excellent communication, coding skills, and exceeded the project scope. Very professional. We look forward to working with him again.",
        "person": "- client"
    },
    {
        "review": "Ioan did front-end development on a supplied PSD. Great clean code.",
        "person": "- client"
    },
    {
        "review": "Very professional and highly recommended! Thanks Ioan!",
        "person": "- client"
    },
    {
        "review": "Good Work !! The task was to convert a psd to css/html. Showed a high level of knowledge in these areas, No rework was required. Exceeded expectations by providing cross browser css support in the deliverables.",
        "person": "- client"
    },
    {
        "review": "Ioan did an awesome job! I would hire him again without any hesitation. Not only did he lived up to my expectations based on the other oDesk feedback, he exceeded them. His communication was excellent. He delivered on time, and under budget. The code I received was of very high quality (I'm a software developer so I knew what I was looking at). Overall, I was extremely happy with the job.",
        "person": "- client"
    },
    {
        "review": "Ergo did an excellent job working with our developer to make a website IE6-friendly. He made an insurmountable task seem effortless, and we will surely hire him again for future work.",
        "person": "- client"
    },
    {
        "review": "Ioan is a top programmer he did a great job and completed the task on time. Great communication and even made suggestions for improvements. Will certainly work with him in the future again and can recommend him to anybody needing a quick professional solution!",
        "person": "- client"
    },
    {
        "review": "Ioan is a great developer.  He was great to work with and I wish I had more projects to give him.",
        "person": "- client"
    },
    {
        "review": "This is the second time I've used Ioan for XHTML and CSS development. This guy kills it. Very easy to work with, few errors and very sensitive to deadlines. I look forward to working with him again soon.",
        "person": "- client"
    },
    {
        "review": "Ioan is simply the best there is when it comes to converting PSDs to HTML. We looked and interviewed many candidates and Ioan does the job the best, fastest with the least fuss. If you need PSD converted to HTML, look no further than Ioan!",
        "person": "- client"
    },
    {
        "review": "Another great job! It's a pleasure to work with this provider, and I have used his services more than once. I would definitely recommend him.",
        "person": "- client"
    },
    {
        "review": "Great contractor, will continue to work with in the future.  Very skilled, very efficient.",
        "person": "- client"
    },
    {
        "review": "Ioan did some great work for me. I'm very pleased. When the need arises, I will definitely use his services again. Thanks!",
        "person": "- client"
    }
]

const RootLayout = () => {
    const refAbout = useRef();
    const refReviews = useRef();
    const refPortfolio = useRef();
    const refContact = useRef();

    const navAboutHandler = () => {
        refAbout.current.scrollIntoView({ behavior: 'smooth' });
    }
    const navReviewsHandler = () => {
        refReviews.current.scrollIntoView({ behavior: 'smooth' });
    }
    const navPortfolioHandler = () => {
        refPortfolio.current.scrollIntoView({ behavior: 'smooth' });
    }
    const navContactHandler = () => {
        refContact.current.scrollIntoView({ behavior: 'smooth' });
    }

    //slider
    const [currIdx, setCurrIdx] = useState(0);
    const [offset, setOffset] = useState(0);

    const refSlide = useRef(null);
    
    const slide = () => {
        const lastIndex = DUMMY_PORTFOLIO_DATA.length - 3;
        if (currIdx > lastIndex) {
            setCurrIdx(0);
            setOffset(0);
            return;
        }
        const figure = refSlide.current.children[currIdx].children[0];
        if (figure) {
          const moveTo = offset - parseInt(figure.offsetWidth);
          setOffset(moveTo);
          setCurrIdx(currIdx + 1);
        }
      };

      const SLIDE_INTERVAL_MS = 2000;
      useLayoutEffect(() => {
        const timeoutId = setTimeout(() => {
          slide();
        }, SLIDE_INTERVAL_MS);
        return () => clearTimeout(timeoutId);
      }, [currIdx, offset]);


      const [testimonalIdx, setTestimonialIdx] = useState(0);
      useLayoutEffect(() => {
        const timer = setInterval(() => {
            if(testimonalIdx >= DUMMY_REVIEW_DATA.length - 1) {
                setTestimonialIdx(0);
                return;
            }
            setTestimonialIdx(testimonalIdx+1);
        }, 10000)
        return () => clearInterval(timer);
      }, [testimonalIdx]);
    return <>
        <Header navAboutHandler={navAboutHandler} navReviewsHandler={navReviewsHandler} navPortfolioHandler={navPortfolioHandler} navContactHandler={navContactHandler} />
        <Outlet />
        <div role="main" className={styles.main}>
            <section className={styles.about} id="#about" ref={refAbout}>
                <div className={styles.content}>
                    <h2>What do I do?</h2>
                    <p>
                        I am a Senior Software Engineer with a strong background in Full-stack Web Development, with experience in ecommerce and media. I have worked for both established companies and numerous startups, bringing knowledge and adaptability to various development environments.
                    </p>

                    <p>
                        As a Fullstack Developer, I specialize in <strong>Python</strong> and its frameworks, particularly <strong>Django</strong>. Additionally, I work extensively with <strong>SQL</strong> for database management and have experience with JavaScript frameworks such as Angular, <strong>React</strong>, and Next.js.
                    </p>
                    <p>
                        My expertise lies in transforming designs into functional websites using frameworks like <strong>Django</strong> and ensuring efficient database interactions with <strong>SQL</strong>. Additionally, I am a proficient Python scripter and have developed numerous scripts for data analysis, data plotting, data pulls, and web scraping.
                    </p>
                    <p>
                        I use <strong>pytest</strong> for unit testing, and <strong>Postman</strong> for API testing and development. For version control, I rely on <strong>Git</strong> and frequently use platforms like <strong>GitHub</strong> and GitLab; and for CI/CD I use <strong>CodeDeploy</strong>, Github actions, or Jenkins.
                    </p>
                    <p>
                        I hold a Master of Science in Computer Science from Georgia Tech and a Bachelor of Science in Computer Science and Mathematics from Concord University.
                    </p>
                    <p>
                        In my free time, I contribute to the tech comunity by helping teach a Graduate-level Operating Systems class at the College of Computing at Georgia Tech.
                    </p>
                    <p>
                        I am passionate about leveraging data to enhance user engagement in the travel industry. Currently, I am developing an AI-driven itinerary planner that aims to revolutionize how travelers plan and experience their journeys by integrating data-driven insights and personalization.
                    </p>
                </div>
            </section>
            <section id="portfolio" className={styles.portfolio} ref={refPortfolio}>
                <div className={styles.container}>
                    <div className={styles.content}>
                        <h2>Things I've built</h2>
                        <div className={styles.hidden}>
                            <div className={styles.items} ref={refSlide} style={{transform: `translateX(${offset}px)`}}>
                                {DUMMY_PORTFOLIO_DATA.map(item => {
                                    return <PortfolioItem item={item} key={item.imageURL} offset={offset} />
                                })}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section id="reviews" className={styles.reviews} ref={refReviews}>
                <div className={styles.container}>
                    <div className={styles.content}>
                        <h2>Stuff people say</h2>
                        <div className={styles.testimonials}>
                            <div className={styles.testimonial}>
                                <div>
                                    <blockquote>
                                        <p>
                                            {DUMMY_REVIEW_DATA[testimonalIdx].review}
                                        </p>
                                    </blockquote>
                                </div>
                                <cite>- client</cite>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section id="contact" className={styles.contact} ref={refContact}>
                <div className={styles.container}>
                    <div className={styles.content}>
                        <h2>Get in touch</h2>
                        <p>
                            You can contact me by email: hello [at] ioanistrate [dot] com <br />
                            Or you can add me on Linkedin in/istrateioan. <br /> <br />
                            Thanks for looking over my stuff, take care now.
                        </p>
                    </div>
                </div>
            </section>
        </div>  
        <Footer />
    </>
};

export default RootLayout;